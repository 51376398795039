<template>
    <div class="">
        <div class="box-body"  style="padding: 0px; width: 100%;position: relative;">
            <div style="overflow:auto;"  class="table100-firstcol">
                <table class="table table-hover table-bordered" style=" border: 0px solid #929292; border-collapse:separate; border-spacing:0px 0px; ">
                    <tbody>
                    <tr>
                        <th  style="white-space: nowrap;background-color:#f0faff;"  v-show="!IsQuery && menupos == '左' && showOperator == true"> 操作
                        </th>
                        <th  v-for="(field, index) in fields" style="min-width:4rem; background-color:#f0faff;" :key="index" v-show="field.Freezeable">{{field.FieldDispName}}</th>
                    </tr>
                    <tr v-for="(item, ri) in localtabler" :key="ri">
                        <td  v-if="!IsQuery && !isEditSaved && menupos == '左' && showOperator == true" :style="'white-space: nowrap; height:'+leftheight[ri]+'px'" >
                            <button  class="btn btn-block" style="background-color: #FFFFFF" @click="chooseBranch(ri,false,false)" ><img src="@/assets/images/home/detailedit.png" style="width: 1rem;height: 1rem"/></button>
                            <div style="display: inline" v-if="!isEditSaved">
                                <button  class="btn btn-block"  style="background-color: #FFFFFF" @click="deleteR(ri)"><img src="@/assets/images/home/detailplus.png" style="width: 1rem;height: 1rem"/></button>
                            </div>
                        </td>
                        <td  v-if="!IsQuery && isEditSaved && menupos == '左' && showOperator == true" :style="'white-space: nowrap; height:'+leftheight[ri]+'px'" >
                            <button class="btn btn-block" style="background-color: #FFFFFF" @click="chooseBranch(ri,false,true)" ><img src="@/assets/images/home/detailview.png" style="width: 1rem;height: 1rem"/></button>
                        </td>
                        <td  v-for="(field, index) in fields" v-show="field.Freezeable && getShow(field)" :style="'white-space: nowrap;color: #888888;min-width: 4rem;height:'+leftheight[ri]+'px'" :key="index">
                            <div v-if="field.BaseDataType != 5 && field.BaseDataType != 6" :style="'font-size: small;text-align:'+(field.FieldAlign ? field.FieldAlign : 'center')">{{ item[field.FieldName] }}</div>
                            <div v-if="field.BaseDataType == 5" style="font-size: small;color:blue" @click="previewAffix(resourceHome + popAffixInfo(item[field.FieldName+'_id']).Url)">{{ item[field.FieldName] }}</div>
                            <div v-if=" field.BaseDataType == 6" style="font-size: small;color:blue"><img :src="resourceHome +  popImageInfo(item[field.FieldName+'_id']).ThumbUrl"
                                                                                                      @click="onClickShowImage(0,item[field.FieldName+'_id'],0)" style="width: 1rem;height: 1rem"/></div>
                            <div v-if="field.AllowBoolean ===true">
                                <van-checkbox v-model="item[field.FieldName]" @click="checkedOneChange(ri,field)"  shape="square" :disabled="field.Editable?operatetype != 0:true"  />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style="overflow:auto;width: 100%" class="wrap-table100-nextcols js-pscroll">
                <div class="table100-nextcols">
                    <table class="table table-hover table-bordered" style=" border: 0px solid #929292; border-collapse:separate; border-spacing:0px 0px; " >
                        <tbody>
                        <tr >
                            <th style="white-space: nowrap;background-color:#f0faff;" v-show="!IsQuery && menupos == '左' && showOperator == true"> 操作
                            </th>
                            <th v-for="(field, index) in fields" style="min-width:4rem;background-color:#f0faff;;white-space: nowrap" v-show="field.Freezeable && getShow(field)" :key="index">{{field.FieldDispName}}</th>
                            <th v-for="(field, index) in fields" style="min-width:4rem;background-color:#f0faff;;white-space: nowrap" v-show="!field.Freezeable && getShow(field)" :key="index">{{field.FieldDispName}}</th>
                            <th  style="white-space: nowrap;background-color:#f0faff;" v-show="!IsQuery && menupos  != '左' && showOperator == true"> 操作
                            </th>
                        </tr>
                        <tr  v-for="(item, ri) in localtabler" :key="ri">
                            <td  v-if="!IsQuery && !isEditSaved && menupos == '左' && showOperator == true" style="white-space: nowrap;">
                                <button  class="btn btn-block" style="background-color: #FFFFFF" @click="chooseBranch(ri,false,false)" ><img src="@/assets/images/home/detailedit.png" style="width: 1rem;height: 1rem"/></button>
                                <div style="display: inline">
                                    <button  class="btn btn-block"  style="background-color: #FFFFFF" @click="deleteR(ri)"><img src="@/assets/images/home/detailplus.png" style="width: 1rem;height: 1rem"/></button>
                                </div>
                            </td>
                            <td  v-if="!IsQuery && isEditSaved && menupos == '左' && showOperator == true"  style="white-space: nowrap; ">
                                <button  class="btn btn-block" style="background-color: #FFFFFF" @click="chooseBranch(ri,false,true)" ><img src="@/assets/images/home/detailview.png" style="width: 1rem;height: 1rem"/></button>
                            </td>
                            <!--冻结列区域-->
                            <td v-for="(field, index) in fields" style="white-space: nowrap;color: #888888 " v-show="field.Freezeable && getShow(field)" :id="field.Freezeable ? (tablecode +'_'+ri+'_'+field.FieldID) : ''" :key="index">
                                <div v-if="field.BaseDataType != 4 && field.BaseDataType != 3 && field.BaseDataType != 6 && field.BaseDataType != 5" :style="'font-size: small;white-space: nowrap;text-align:'+(field.FieldAlign ? field.FieldAlign : 'center')">
                                    <span v-if="item[field.FieldName] == null"></span>
                                    <span  v-else v-for="(strin, index) in item[field.FieldName].toString().split('\\n')" :key="index">{{strin}}<br></span>
                                </div>
                                <div v-if="field.BaseDataType == 4" :style="'font-size: small;text-align:text-align:'+(field.FieldAlign ? field.FieldAlign : 'center')">{{ item[field.FieldName] }}</div>
                                <div v-if="field.BaseDataType == 3" :style="'font-size: small;text-align:text-align:'+(field.FieldAlign ? field.FieldAlign : 'center')">{{ item[field.FieldName] }}</div>
                                <div v-if="field.BaseDataType == 6" style="font-size: small;color:blue" @click="previewAffix(resourceHome + popAffixInfo(item[field.FieldName+'_id']).Url)">{{ item[field.FieldName] }}</div>
                                <div v-if="field.BaseDataType == 5" style="font-size: small;color:blue" ><img  :src="resourceHome +  popImageInfo(item[field.FieldName+'_id']).ThumbUrl"
                                                                                                                                          @click="onClickShowImage(0,item[field.FieldName+'_id'],0)" style="width: auto;height: auto"/></div>

                                <div v-if="field.AllowBoolean ===true" :style="'height:'+leftheight[ri] " >
                                    <van-checkbox v-model="item[field.FieldCode]" @click="checkedOneChange(ri,field)"  shape="square" :disabled="field.Editable?operatetype != 0:true"   style="text-align: center"/>
                                    <!--<input type="checkbox" @change="checkedOneChange(ri,field)" v-model="parseInt(item[field.FieldCode])" :disabled="field.Editable?operatetype != 0:true" style="zoom:150%;"/>-->
                                </div>
                            </td>
                            <!--非冻结列区域-->
                            <td v-for="(field, index) in fields" style="white-space: nowrap;color: #888888;" v-show="!field.Freezeable && getShow(field)" :id="(!field.Freezeable) ? (tablecode+'_'+ri+'_'+field.FieldID) : ''"  :key="index">
                                <!--<div v-if="field.AllowBoolean ===false" style="font-size: small">{{ item[field.FieldCode] }}</div>-->
                                <!--{{field}}-->
                                <div v-if="field.BaseDataType != 4 && field.BaseDataType != 3 && field.BaseDataType != 6 && field.BaseDataType != 5" :style="'font-size: small;white-space: nowrap;text-align:'+(field.FieldAlign ? field.FieldAlign : 'center')">
                                    <span v-if="item[field.FieldName] == null"></span>
                                    <span  v-else v-for="(strin, index) in item[field.FieldName].toString().split('\\n')" :key="index">{{strin}}<br></span>
                                </div>
                                <div v-if="field.BaseDataType == 4"  :style="'font-size: small;text-align:'+(field.FieldAlign ? field.FieldAlign : 'center')">{{ item[field.FieldName] }}</div>
                                <div v-if="field.BaseDataType == 3" :style="'font-size: small;text-align:'+(field.FieldAlign ? field.FieldAlign : 'center')">{{ item[field.FieldName] }}</div>
                                <div v-if="field.BaseDataType == 6" style="font-size: small;color:blue" @click="previewAffix(resourceHome + popAffixInfo(item[field.FieldName+'_id']).Url)">{{ item[field.FieldName] }}</div>
                                <div v-if="field.BaseDataType == 5" style="font-size: small;color:blue" >
                                    <img  :src="resourceHome +  popImageInfo(item[field.FieldName+'_id']).ThumbUrl"
                                          @click="onClickShowImage(0,item[field.FieldName+'_id'],0)"  style="width: auto;height: auto"/>
                                </div>
                                <div v-if="field.AllowBoolean ===true">
                                    <van-checkbox v-model="item[field.FieldCode]" @click="checkedOneChange(ri,field)"  shape="square" :disabled="field.Editable?operatetype != 0:true"   style="text-align: center"/>
                                    <!--<input type="checkbox" @change="checkedOneChange(ri,field)" v-model="parseInt(item[field.FieldCode])" :disabled="field.Editable?operatetype != 0:true" style="zoom:150%;"/>-->
                                </div>
                            </td>
                            <td  v-if="!IsQuery && operatetype == 0 && menupos != '左' && showOperator == true" style="white-space: nowrap;">
                                <button  class="btn btn-block" style="background-color: #FFFFFF" @click="chooseBranch(ri,false,flase)" ><img src="@/assets/images/home/detailedit.png" style="width: 1rem;height: 1rem"/></button>
                                <div style="display: inline" v-if="!hideRomoveRow">
                                    <button   class="btn btn-block"  style="background-color: #FFFFFF" @click="deleteR(ri)"><img src="@/assets/images/home/detailplus.png" style="width: 1rem;height: 1rem"/></button>
                                </div>
                            </td>
                            <td  v-if="!IsQuery && operatetype != 0 && menupos != '左' && showOperator == true" style="white-space: nowrap;">
                                <button class="btn btn-block" style="background-color: #FFFFFF" @click="chooseBranch(ri,false,true)" ><img src="@/assets/images/home/detailview.png" style="width: 1rem;height: 1rem"/></button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <van-pagination v-if="tablers.length > 10" v-model="page" :total-items="tablers.length" :items-per-page="10">
        </van-pagination>
    </div>
</template>

<script>
import './css/AdminLTE.css'
import './css/AdminLTE.min.css'
import { Checkbox, Pagination, ImagePreview } from 'vant'
export default {
    name: 'NxTable',
    props: {       pageSetup: Object,
        IsQuery:{
            type: Boolean,
            default: false
        },
        fields: {
            type: Array,
            required: true
        },
        showOperator: {
            type: Boolean,
            required: true
        },
        operatetype:{
            type: Number,
            required: true,
            default: 2
        },
        tablers: {
            type: Array,
            required: true
        },
        // addRow: {
        //     type: Function,
        //     required: false
        // },
        editRow:{
            type: Function,
            required: false
        },
        deleteRow:{
            type: Function,
            required: false
        },
        // tableinfo: {
        //     type: Object,
        //     default: () => {}
        // },
        menupos: {
            type: String,
            required: "右"
        },
        // setTableFieldValue:{
        //     type: Function,
        //     required: false
        // },
        // singleAffixes:{
        //     type:Array,
        //     required: true
        // },
        // singleImages:{
        //     type:Array,
        //     required: true
        // },
        // fromType:{
        //     type:String,
        //     default:"report"
        // },
        // hideAddRow:{
        //     type:Boolean,
        //     default:false
        // },
        hideRomoveRow:{
            type:Boolean,
            default:false
        },
        mastertable: Object,
        tablecode: String,
        isEditSaved: Boolean,
        addFocuseRow: Function,
        editFieldBefore: Function,
        editFieldAfter: Function,
        onClickUploadImage: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        resourceHome: String,
        changeValue: Function,
        popAffixInfo: Function,
        previewAffix: Function,
        tables: Array,
        onClickUploadAffix: Function
    },
    data () {
        return {
            showOverlay: false,
            items: [],
            targetFieldId: '',
            leftheight: [],
            paginate: {
                currentPage: 1,
                totalRows: 0,
                perPage: 15
            },
            localtabler: [],
            nowPage: [],
            pageCenter: 2,
            page: 1,
            pageSize: 10,
            nowPagelength: 4
        }
    },
    computed: {
    },
    components: {
        [Checkbox.name]: Checkbox,
        [Pagination.name]: Pagination,
        [ImagePreview.name]: ImagePreview
    },
    mounted () {
        console.log(this.fields)
        const tableList = this.$utils.deepClone(this.tables)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldID.toString() === this.fields[0].FieldID.toString()) {
                        console.log(table.Fields[j])
                    }
                }
            }
        }
    },
    watch:{
        tablers: function(val) {
            if(val == undefined)
                return
            this.initPage()
            if (this.tablers.length > 10) {
                this.localtabler = this.tablers.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
            }
            else {
                this.localtabler = this.tablers;
            }
            this.refreshFrozenArea()
        },
        showFocuseTable(){
            this.refreshFrozenArea()
        },
        pageCenter(){
            if(this.pageCenter < 2)
            {
                this.pageCenter = 2
            }
            this.nowPage = []
            this.nowPagelength = 4
            this.localtabler = this.tablers.slice(this.pageSize*(this.page -1),this.pageSize*this.page)
            if(Math.ceil(this.tablers.length/this.pageSize) > 4) {
                for (let i = 0; i<4; i++)
                {
                    this.nowPage.push(i+1)
                }
                if (this.pageCenter === 2) {
                    for (let i = 0; i < 4; i++) {
                        if (i === 3) {
                            this.nowPage[i] = "...";
                        } else {
                            this.nowPage[i] = i + 1;
                        }
                    }
                } else if (this.pageCenter >= Math.ceil(this.tablers.length / this.pageSize) - 1) {
                    for (let i = 0; i < 4; i++) {
                        if (i === 0) {
                            this.nowPage[i] = "...";
                        } else {
                            this.nowPage[i] = Math.ceil(this.tablers.length / this.pageSize) + i - 3;
                        }
                    }
                } else {
                    this.nowPagelength = 5
                    for (let i = 0; i < 4; i++) {
                        if (i === 3) {
                            this.nowPage[i] = "..."
                        } else {
                            this.nowPage[i] = this.pageCenter - 1 + i;
                        }
                    }
                    console.log(this.nowPage)
                }
            }
            else{
                for (let i = 0; i<Math.ceil(this.tablers.length/this.pageSize); i++)
                {
                    this.nowPage.push(i+1)
                }
            }
        },
        page(){
            this.localtabler = this.tablers.slice(this.pageSize*(this.page -1),this.pageSize*this.page)
        }
    },
    created () {
        this.initPage()
        if (this.tablers.length > 10) {
            this.localtabler = this.tablers.slice(this.pageSize * (this.page - 1), this.pageSize * this.page)
        }
        else {
            this.localtabler = this.tablers;
        }
        console.log(this.localtabler)

        this.targetFieldId = this.fields[0].FieldID
        this.refreshFrozenArea()
    },
    methods: {
        initPage(){
            this.page = 1
            this.pageCenter = 2
            this.nowPagelength = 4
            this.nowPage = []
            var len = this.tablers.length/this.pageSize;
            if(len > 4){
                for(var i = 0; i < 4; i++){
                    if(i===3){
                        this.nowPage.push("...")
                    }else{
                        this.nowPage.push(i + 1)
                    }
                }
            }else{
                for(let i = 0; i < len; i++){
                    this.nowPage.push(i + 1)
                }
            }
        },
        refreshFrozenArea()
        {
            var self = this
            setTimeout(function () {
                self.leftheight = []
                if(self.targetFieldId!="") {
                    for (var i = 0; i < self.localtabler.length; i++) {
                        if(document.getElementById(self.tablecode + '_' + i+ '_' + self.targetFieldId)!="" && document.getElementById(self.tablecode + '_' + i+ '_' + self.targetFieldId)!=undefined) {
                            self.leftheight.push(document.getElementById(self.tablecode + '_' + i + '_' + self.targetFieldId).offsetHeight - 4)
                        }
                    }
                }
            }, 200);
        },
        deleteR(index) {
            var ri = (this.page-1) * this.pageSize + index;
            this.deleteRow(ri, this.tablecode)
        },
        chooseBranch(index,isadd,isshow)
        {
            var ri = (this.page-1) * this.pageSize + index;
            this.editRow(ri, isadd,isshow)
        },
        getShow (field) {
            const tableList = this.$utils.deepClone(this.tables)
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                if (table.TableCode === this.tablecode) {
                    for (let j = 0; j < table.Fields.length; j++) {
                        if (table.Fields[j].FieldID.toString() === field.FieldID.toString()) {
                            return table.Fields[j].Visable
                        }
                    }
                }
            }
            return false
        }
    }
}
</script>

<style scoped>
    table  tr th{

        font-weight:300;
        -webkit-box-flex: 0;
        -webkit-flex: none;
        flex: none;
        box-sizing: border-box;
        color: #81BAF3;
        text-align: center;
        word-wrap: break-word;
    }
    .page1 {
        display: flex;
        justify-content: center;
        margin: .1rem 0;
    span {
        height: 1rem;
        width: 1rem;
        border-radius: .03rem;
        color: green;
        text-align: center;
        margin: 0 .07rem;
    }
    }

    .table100-firstcol {
        background-color: #fff;
        position: absolute;
    }
    .wrap-table100-nextcols {
        width: 100%;
        overflow: auto;
        padding-bottom: 28px;
    }
    .table100-nextcols table {
        table-layout: fixed;
    }
</style>
