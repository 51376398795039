var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.field.Visable)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"myInput report-v2"},[_c('van-cell-group',{staticStyle:{"background-color":"#F2F3F5"}},[(!_vm.isList)?_c('van-field',{style:('height: ' + _vm.config.cellHeight + 'px; line-height: ' + _vm.config.cellHeight + 'px;'),attrs:{"required":_vm.field.Nullable == true,"label-width":_vm.config.labelWidth},scopedSlots:_vm._u([{key:"button",fn:function(){return [(!_vm.isEditSaved && _vm.field.Editable && _vm.field.QRCoder !== null)?_c('van-button',{attrs:{"size":"small","type":"default","icon":require('@/assets/images/report/barcode.png')},on:{"click":_vm.sgBarCodeV2}}):_vm._e(),(_vm.field.Linkers != null  && _vm.field.Linkers.length > 0)?_c('van-button',{staticStyle:{"width":"12px","height":"12px"},attrs:{"type":"default","icon":require('@/assets/images/report/linker.png')},on:{"click":function($event){return _vm.linkReport()}}}):_vm._e()]},proxy:true}],null,false,4143404527)},[_c('template',{slot:"label"},[(_vm.config.labelWidth !== '0')?_c('div',{style:('width: ' + _vm.config.labelWidth + 'px; color: ' + _vm.pageSetup.wordColor +'; font-weight: ' + _vm.weightList[_vm.pageSetup.wordWeight] + '; font-size: ' + _vm.sizeList[_vm.pageSetup.wordSize] + 'px; height: ' +
          _vm.config.cellHeight + 'px; line-height: ' + _vm.config.cellHeight + 'px;')},[_vm._v(_vm._s(_vm.config.name))]):_vm._e()]),_c('template',{slot:"input"},[((_vm.config.type)==='checkbox'&&(_vm.config.rows < 2))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode]),expression:"dataSet[tablecode][rowindex][config.FieldCode]"}],style:('width:100%; color: ' + _vm.pageSetup.inputColor + ' font-weight: ' + _vm.weightList[_vm.pageSetup.inputWeight] + '; font-size: ' + _vm.sizeList[_vm.pageSetup.inputSize] + 'px; background-color: #fff; height: ' +
        _vm.config.cellHeight + 'px; line-height: ' +  _vm.config.cellHeight
            + 'px; '),attrs:{"placeholder":_vm.config.description,"rows":_vm.config.rows,"disabled":_vm.isEditSaved || !_vm.field.Editable || _vm.config.audioToText,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode])?_vm._i(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode],null)>-1:(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode])},on:{"change":[function($event){var $$a=_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dataSet[_vm.tablecode][_vm.rowindex], _vm.config.FieldCode, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dataSet[_vm.tablecode][_vm.rowindex], _vm.config.FieldCode, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dataSet[_vm.tablecode][_vm.rowindex], _vm.config.FieldCode, $$c)}},_vm.changeV],"focus":_vm.editFieldBefor}}):((_vm.config.type)==='radio'&&(_vm.config.rows < 2))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode]),expression:"dataSet[tablecode][rowindex][config.FieldCode]"}],style:('width:100%; color: ' + _vm.pageSetup.inputColor + ' font-weight: ' + _vm.weightList[_vm.pageSetup.inputWeight] + '; font-size: ' + _vm.sizeList[_vm.pageSetup.inputSize] + 'px; background-color: #fff; height: ' +
        _vm.config.cellHeight + 'px; line-height: ' +  _vm.config.cellHeight
            + 'px; '),attrs:{"placeholder":_vm.config.description,"rows":_vm.config.rows,"disabled":_vm.isEditSaved || !_vm.field.Editable || _vm.config.audioToText,"type":"radio"},domProps:{"checked":_vm._q(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode],null)},on:{"change":[function($event){return _vm.$set(_vm.dataSet[_vm.tablecode][_vm.rowindex], _vm.config.FieldCode, null)},_vm.changeV],"focus":_vm.editFieldBefor}}):(_vm.config.rows < 2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode]),expression:"dataSet[tablecode][rowindex][config.FieldCode]"}],style:('width:100%; color: ' + _vm.pageSetup.inputColor + ' font-weight: ' + _vm.weightList[_vm.pageSetup.inputWeight] + '; font-size: ' + _vm.sizeList[_vm.pageSetup.inputSize] + 'px; background-color: #fff; height: ' +
        _vm.config.cellHeight + 'px; line-height: ' +  _vm.config.cellHeight
            + 'px; '),attrs:{"placeholder":_vm.config.description,"rows":_vm.config.rows,"disabled":_vm.isEditSaved || !_vm.field.Editable || _vm.config.audioToText,"type":_vm.config.type},domProps:{"value":(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode])},on:{"change":_vm.changeV,"focus":_vm.editFieldBefor,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataSet[_vm.tablecode][_vm.rowindex], _vm.config.FieldCode, $event.target.value)}}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode]),expression:"dataSet[tablecode][rowindex][config.FieldCode]"}],style:('width:100%; color: ' + _vm.pageSetup.inputColor + ' font-weight: ' + _vm.weightList[_vm.pageSetup.inputWeight] + '; font-size: ' + _vm.sizeList[_vm.pageSetup.inputSize] + 'px;  background-color: #fff; height: ' +
        _vm.config.cellHeight + 'px; line-height: ' +  (_vm.config.cellHeight/_vm.config.rows - 5)
            + 'px; '),attrs:{"placeholder":_vm.config.description,"rows":_vm.config.rows,"type":_vm.config.type,"disabled":_vm.isEditSaved || !_vm.field.Editable || !_vm.field.AllowManual || _vm.config.audioToText},domProps:{"value":(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode])},on:{"change":_vm.changeV,"focus":_vm.editFieldBefor,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataSet[_vm.tablecode][_vm.rowindex], _vm.config.FieldCode, $event.target.value)}}}),_vm._v(" "),(_vm.config.audioToText)?_c('voice-input-button',{attrs:{"interactiveMode":"touch","color":"#000","tipPosition":"top"},on:{"record":_vm.showResult,"record-start":_vm.recordStart,"record-stop":_vm.recordStop,"record-blank":_vm.recordNoResult,"record-failed":_vm.recordFailed,"record-ready":_vm.recordReady,"record-complete":_vm.recordComplete},model:{value:(_vm.dataSet[_vm.tablecode][_vm.rowindex][_vm.config.FieldCode]),callback:function ($$v) {_vm.$set(_vm.dataSet[_vm.tablecode][_vm.rowindex], _vm.config.FieldCode, $$v)},expression:"dataSet[tablecode][rowindex][config.FieldCode]"}},[_c('template',{slot:"no-speak"},[_vm._v("没听清您说的什么")])],2):_vm._e()],1)],2):_vm._e()],1)],1),(_vm.isList && _vm.config.type === 'text')?_c('van-field',{style:('height: ' + _vm.config.cellHeight + 'px; line-height: ' + _vm.config.cellHeight + 'px;'),attrs:{"rows":_vm.config.rows,"type":_vm.config.type,"label-width":_vm.config.labelWidth}},[_c('template',{slot:"label"},[(_vm.config.labelWidth !== '0')?_c('div',{style:('width: ' + _vm.config.labelWidth + 'px; color: ' + _vm.config.wordColor +'; font-weight: ' + _vm.config.wordWeight + '; font-size: ' + _vm.config.wordSize + 'px; height: ' +
           (_vm.config.cellHeight - 20) + 'px; line-height: ' + (_vm.config.cellHeight - 20) + 'px;')},[_vm._v(_vm._s(_vm.config.name + ':'))]):_vm._e()]),_c('template',{slot:"input"},[_c('div',{style:('color: ' + _vm.config.wordColor +'; font-weight: ' + _vm.config.wordWeight + '; font-size: ' + _vm.config.wordSize + 'px;  height: ' +
          (_vm.config.cellHeight - 20) + 'px; line-height: ' + (_vm.config.cellHeight - 20) + 'px; margin-top:' + (_vm.config.cellHeight < 48 ? (_vm.config.cellHeight - 45) : 0) + 'px')},[_vm._v(_vm._s(_vm.dataRow['app'][_vm.field.FieldCode]))])])],2):_vm._e(),(_vm.isList && _vm.config.type === 'textarea')?_c('van-field',{attrs:{"rows":_vm.config.rows,"type":_vm.config.type,"label-width":_vm.config.labelWidth}},[_c('template',{slot:"label"},[(_vm.config.labelWidth !== '0')?_c('div',{style:('width: ' + _vm.config.labelWidth + 'px; color: ' + _vm.config.wordColor +'; font-weight: ' + _vm.config.wordWeight + '; font-size: ' + _vm.config.wordSize + 'px;  height: ' +
           (_vm.config.cellHeight - 20) + 'px; line-height: ' + (_vm.config.cellHeight - 20) + 'px;')},[_vm._v(_vm._s(_vm.config.name + ':'))]):_vm._e()]),_c('template',{slot:"input"},[_c('div',{style:('width:100%; background-color: rgba(243, 240, 247, 0.3);  word-wrap: break-word; overflow-y:auto; color: ' + _vm.config.wordColor +'; font-weight: ' + _vm.config.wordWeight + '; font-size: ' + _vm.config.wordSize + 'px;  height: ' +
           _vm.config.cellHeight + 'px;; margin-top:' + (_vm.config.cellHeight < 48 ? (_vm.config.cellHeight - 45) : 0) + 'px')},[_vm._v(_vm._s(_vm.dataRow['app'][_vm.field.FieldCode]))])])],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }