<template>
  <div style="position: relative;"  v-if="field.Visable">
    <div
            v-if="!isList && (dataSet[tablecode][rowindex][config.FieldCode]==null || dataSet[tablecode][rowindex][config.FieldCode]=='')">
      <van-uploader @click-upload="editFieldBefor"
                    accept="video/*" :after-read="onClickUploadVideo" :disabled="isEditSaved || !field.Editable" :style="'height:' + (config.cellHeight - 20) + 'px;'"/>
    </div>
    <div
            v-if="!isList && (dataSet[tablecode][rowindex][config.FieldCode]!=null && dataSet[tablecode][rowindex][config.FieldCode]!='')"
            class="img-box-main">
      <div class="img-line-main">
        <video controls="controls" class="detail-img"
               :src="resourceHome + thumbUrl"
               width="100%" :height="config.cellHeight"></video>
        <van-icon name="delete-o" class="delete-voideo-icon"
                  @click="removeAffix(tablecode,config.FieldCode,rowindex)" v-if="!isEditSaved && field.Editable"/>
      </div>
    </div>
    <div v-if="isList"  class="img-box-main">
      <div class="img-line-main">
        <!--{{dataRow}}-->
        <video controls="controls" class="pre-item-image" :src="resourceHome + thumbUrl" mode="aspectFill"  width="100%" :height="config.cellHeight"></video>
      </div>
    </div>
  </div>
</template>

<script>
import { Uploader, Loading, Image, Icon } from 'vant'
export default {
    name: 'nxMobileVideo',
    components: {
        [Image.name]: Image,
        [Uploader.name]: Uploader,
        [Image.name]: Image,
        [Uploader.name]: Uploader,
        [Loading.name]: Loading,
        [Icon.name]: Icon

    },
    props: {       pageSetup: Object,
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        value: String,
        config: Object,
        children: Array,
        cssdiy: Object,
        label: String,
        isEditSaved: Boolean,
        onClickUploadImage: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        resourceHome: String,
        rowindex: Number,
        tablecode: String,
        onClickUploadAffix: Function,
        onClickUploadVideo: Function,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        removeAffix: Function,
        dataRow: Object,
        isList: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null,
            thumbUrl: ''
        }
    },
    watch: {
        async refresh () {
            if (this.isList) {
                this.thumbUrl = await this.getImageOrAffixUrl(this.dataRow['app'][this.config.FieldCode])
            } else {
                this.thumbUrl = await this.getImageOrAffixUrl(this.dataSet[this.tablecode][this.rowindex][this.config.FieldCode+'_id'])
            }
        },
        dataSet: {
            async handler () {
                if (this.isList) {
                    this.thumbUrl = await this.getImageOrAffixUrl(this.dataRow['app'][this.config.FieldCode])
                } else {
                    this.thumbUrl = await this.getImageOrAffixUrl(this.dataSet[this.tablecode][this.rowindex][this.config.FieldCode+'_id'])
                }
            },
            deep: true
        }
    },
    async mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            for (let j = 0; j < table.Fields.length; j++) {
                if (table.Fields[j].FieldCode === this.config.FieldCode) {
                    this.field = table.Fields[j]
                    break
                }
            }
            if (this.field !== null) {
                break
            }
        }
        if (this.isList) {
            this.thumbUrl = await this.getImageOrAffixUrl(this.dataRow['app'][this.config.FieldCode])
        } else {
            this.thumbUrl = await this.getImageOrAffixUrl(this.dataSet[this.tablecode][this.rowindex][this.config.FieldCode+'_id'])
        }
    },
    methods: {
        async popVideoInfo (value) {
            const result = await this.$api.getImageOrAffixUrl({ NxStr: value })
            return result.affixinfo
        },
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
        },
        async getImageOrAffixUrl (id) {
            const result = await this.$api.getImageOrAffixUrl({ NxStr: id })
            if (result.Code === 0) {
                return result.affixinfo.Url
            } else {
                return ''
            }
        }
    }
}
</script>

<style scoped>
  .delete-voideo-icon {
    float: right;
    z-index: 99;
    width: 1rem;
    height: 1rem;
  }

</style>
