<template>
  <div style="position: relative;">
    <van-field
      :placeholder="config.description"  :rows="config.rows" :type="config.type" readonly :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'"
             :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
             config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px; margin-top:-10px'">
          {{ config.name }}
        </div>
      </template>
      <template slot="input">
          <div style="display:none">
              <audio controls="controls" ref="audio" :src="thumbUrl">
                  <!--<source :src="thumbUrl" type="audio/mp3" />-->
              </audio>
          </div>
          <div v-if="thumbUrl !== ''" class="voice-bg" @click="handlePlay"  :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight + 'px;'">
              <img
                      v-if="isPlay"
                      :height="config.cellHeight*0.8"
                      src="@/assets/images/report/voice-play.gif"
                      alt=""
              />
              <img
                      v-else
                      :height="config.cellHeight*0.8"
                      src="@/assets/images/report/voice.png"
                      alt=""
              />
              <span>{{ audioTime}}"</span>
          </div>
          <!--<div class="newAudio">-->
              <!--<div class="voice-container">-->
                  <!--<div-->
                          <!--class="play-button"-->
                          <!--id="play-button"-->
                          <!--:class="{ 'play-button-pause': isPlay }"-->
                          <!--@click="play($event)"-->
                  <!--&gt;-->
                      <!--<audio id="audio" ref="audio" :src="thumbUrl"></audio>-->
                  <!--</div>-->
                  <!--<div class="progress" ref="progressGray">-->
                      <!--<div class="progress-red-con" ref="progress-red-con">-->
                          <!--<div class="progress-red"></div>-->
                      <!--</div>-->
                  <!--</div>-->
                  <!--<div v-if="isPlay" class="count-down">{{ progressStart }}s</div>-->
                  <!--<div v-else class="count-down">-->
                      <!--{{ audioPalyTime > 0 ? audioPalyTime : progressStart }}s-->
                  <!--</div>-->
              <!--</div>-->
          <!--</div>-->
      </template>
      <template #button>
        <van-button v-if="!isEditSaved && field.Editable"  size="small" type="default"  style="" @touchstart="handleStart"
                    @touchend.native.prevent="handleEnd" :icon="require('@/assets/images/report/record.png')"></van-button>
        <van-button v-if="!isEditSaved && field.Editable"  size="small" type="default"  style="" @click="removeAffix(tablecode,config.FieldCode,rowindex)" :icon="require('@/assets/images/delete.png')"></van-button>
       </template>
    </van-field>
    <!--<div>sss</div>-->
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
import { Field, Button } from 'vant'
import Recorder from 'js-audio-recorder'
import lamejs from 'lamejs'
export default {
  name: 'nxMobileAudioRecorder',
    components: {
        [Field.name]: Field,
        [Button.name]: Button

    },
    props: {       pageSetup: Object,
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        value: String,
        config: Object,
        children: Array,
        cssdiy: Object,
        label: String,
        isEditSaved: Boolean,
        onClickUploadImage: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        resourceHome: String,
        rowindex: Number,
        tablecode: String,
        onClickUploadAffix: Function,
        onClickUploadVideo: Function,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        onClickUploadAudio: Function,
        removeAffix: Function,
        dataRow: Object,
        isList: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            timer: '',
            audioTime: '',
            audioPalyTime: 0,
            progressStart: 0,
            isPlay: false,
            weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null,
            recorder: null,
            thumbUrl: '',
            playing: false,
            duration: 0,
            audio: null,
            targetNode: null
        }
    },
    watch: {
        async refresh () {
            if (this.isList) {
                this.thumbUrl = await this.getImageOrAffixUrl(this.dataRow['app'][this.config.FieldCode])
            } else {
                this.thumbUrl = await this.getImageOrAffixUrl(this.dataSet[this.tablecode][this.rowindex][this.config.FieldCode+'_id'])
            }
            setTimeout(() => {
                const audio = this.$refs.audio
                if (audio) {
                    // 录音资源
                    audio.src = this.thumbUrl
                    audio.load()
                    audio.oncanplay = () => {
                        // 获取录音的时间长度
                        this.audioTime = Math.round(audio.duration)
                    }
                }
            }, 500)
        }
    },
    async mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            for (let j = 0; j < table.Fields.length; j++) {
                if (table.Fields[j].FieldCode === this.config.FieldCode) {
                    this.field = table.Fields[j]
                    break
                }
            }
            if (this.field !== null) {
                break
            }
        }
        if (this.isList) {
            this.thumbUrl = await this.getImageOrAffixUrl(this.dataRow['app'][this.config.FieldCode])
        } else {
            this.thumbUrl = await this.getImageOrAffixUrl(this.dataSet[this.tablecode][this.rowindex][this.config.FieldCode+'_id'])
        }
        setTimeout(() => {
            const audio = this.$refs.audio
            if (audio) {
                // 录音资源
                audio.src = this.thumbUrl
                audio.load()
                audio.oncanplay = () => {
                    // 获取录音的时间长度
                    this.audioTime = Math.round(audio.duration)
                }
            }
        }, 500)
    },
    methods: {
        handlePlay() {
            let audio = this.$refs.audio;
            if (!this.isPlay) {
                audio.play()
                this.isPlay = true
                this.watchEnd()
            } else {
                audio.pause()
                clearTimeout(this.timer)
                this.isPlay = false
                audio.currentTime = 0
            }
        },
        watchEnd() {
            let that = this;
            this.timer = setTimeout(() => {
                that.isPlay = false;
            }, (that.audioTime + 1) * 1000);
        },
        handleStart() {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
            this.recorder = new Recorder()
            Recorder.getPermission().then(() => {
                console.log('开始录音')
                this.recorder.start() // 开始录音
            }, (error) => {
                this.$message({
                    message: '请先允许该网页使用麦克风',
                    type: 'info'
                })
                console.log(`${error.name} : ${error.message}`)
            })
        },
        handleEnd () {
            console.log('停止录音')
            this.recorder.stop()
            const mp3Blob = this.convertToMp3(this.recorder.getWAV())
            var reader = new FileReader()
            reader.readAsDataURL(mp3Blob)
            const that = this
            reader.onload = function (e) {
                that.onClickUploadAudio(e.target.result, 0)
            }
        },
        convertToMp3(wavDataView) {
            // 获取wav头信息
            const wav = lamejs.WavHeader.readHeader(wavDataView) // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
            const { channels, sampleRate } = wav
            const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128)
            // 获取左右通道数据
            const result = this.recorder.getChannelData()
            const buffer = []
            const leftData =
                result.left &&
                new Int16Array(result.left.buffer, 0, result.left.byteLength / 2)
            const rightData =
                result.right &&
                new Int16Array(result.right.buffer, 0, result.right.byteLength / 2)
            const remaining = leftData.length + (rightData ? rightData.length : 0)
            const maxSamples = 1152
            for (let i = 0; i < remaining; i += maxSamples) {
                const left = leftData.subarray(i, i + maxSamples)
                let right = null
                let mp3buf = null
                if (channels === 2) {
                    right = rightData.subarray(i, i + maxSamples)
                    mp3buf = mp3enc.encodeBuffer(left, right)
                } else {
                    mp3buf = mp3enc.encodeBuffer(left)
                }
                if (mp3buf.length > 0) {
                    buffer.push(mp3buf)
                }
            }
            const enc = mp3enc.flush()
            if (enc.length > 0) {
                buffer.push(enc)
            }
            return new Blob(buffer, { type: "audio/mp3" })
        },
        async popVideoInfo (value) {
            console.log(value)
            const result = await this.$api.getImageOrAffixUrl({ NxStr: value })
            console.log(result)
            return result.affixinfo
        },
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
        },
        async getImageOrAffixUrl (id) {
            const result = await this.$api.getImageOrAffixUrl({ NxStr: id })
            if (result.Code === 0) {
                return result.affixinfo.Url
            } else {
                return ''
            }
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer);
    }
}
</script>

<style scoped>
  .voice-message {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon {
    width: 20px;
    height: 20px;
    background-color: #007aff;
    border-radius: 50%;
    margin-right: 10px;
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  .icon.active {
    opacity: 1;
  }

  .duration {
    font-size: 14px;
    color: #999;
  }
</style>
