<template>
  <div style="position: relative; margin-top: 10px;">
    <div class="tab_name"  v-if="config.showLabel">
      <div class="tab_pro" id="tab_pro">{{config.label}}</div>
    </div>

    <div class="tab_ct" name="tab_one" id="dt_one" style="background-color: #ffffff; border-radius: 0px 8px 8px 8px;">
      <div  v-if="config.showLabel" style="height: 3px;"></div>
      <van-collapse v-model="activeNames" v-if="config.fieldJson.length > 0">
        <van-swipe-cell  v-for="(row, ri) in dataSet[config.TableCode]" :key="ri" v-show="!(config.OutFieldCode !== undefined && config.OutFieldCode !== null && config.OutFieldCode !== '') || dataSet[tablecode][rowindex][config.MasterFieldCode] === row[config.OutFieldCode]">
          <div v-if="ri > 0 && !(config.OutFieldCode !== undefined && config.OutFieldCode !== null && config.OutFieldCode !== '')" style="height: 10px;background-color: #F2F3F5;"></div>
          <van-collapse-item v-if="config.showCollapse"   :title="config.showSchema ? rowSchem(dataSet, config, ri) : '第' + (rows[ri] + 1) + '行'" :name="ri">
            <template #title>
              <div v-if="config.showSchema">
                <div v-if="config.showSchemaTitle">
                <span v-for="(item, index) in rowSchem(dataSet, config, ri).split(' ')" :key="index" v-show="item !== ''">
                  <span :style="'color:' + pageSetup.wordColor+ '; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px;'">{{item.split(':')[0]}}：</span>
                  <span :style="'color: ' + pageSetup.inputColor + ' font-weight: ' + weightList[pageSetup.inputWeight] + '; font-size: ' + sizeList[pageSetup.inputSize] + 'px;'">{{item.split(':')[1]}} </span>
                </span>
                </div>
                <div v-else>
                  <span v-for="(item, index) in rowSchem(dataSet, config, ri).split(' ')" :key="index" v-show="item !== ''">
                  <span :style="'color: ' + pageSetup.inputColor + ' font-weight: ' + weightList[pageSetup.inputWeight] + '; font-size: ' + sizeList[pageSetup.inputSize] + 'px;font-weight: bold'">{{index === 0 ? item.split(':')[1] : '/' + item.split(':')[1]}}</span>
                </span>
                </div>
              </div>
              <div v-else>
                <span style="font-weight: bold">{{'第' + (rows[ri] + 1) + '行'}}</span>
              </div>
            </template>
            <div v-if="activeNames.indexOf(ri) >= 0">
              <component
                      :page-setup="pageSetup"
                      :is="item.component"
                      v-for="(item, index) in config.fieldJson"
                      :table="item.table"
                      :data-set="dataSet"
                      :key="index"
                      :value="item.value"
                      :label="item.label"
                      :config="item.setStyle"
                      :id="item.id"
                      :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' : item.setStyle.col === '1/4' ? '25%' : '100%'
                }"
                      :is-edit-saved="isEditSaved"
                      :resource-home = 'resourceHome'
                      :on-click-upload-image="onClickUploadImage"
                      :pop-image-info="popImageInfo"
                      :on-click-show-image="onClickShowImage"
                      :remove-image="removeImage"
                      :remove-affix="removeAffix"
                      :rowindex = "ri"
                      :tablecode = "config.TableCode"
                      :change-value="changeValue"
                      :edit-field-before="editFieldBefore"
                      :edit-field-before-v2="editFieldBeforeV2"
                      :on-click-upload-affix="onClickUploadAffix"
                      :pop-affix-info="popAffixInfo"
                      :preview-affix="previewAffix"
                      :edit-field-after="editFieldAfter"
                      :field="item.setStyle.field"
                      :tables="tables"
                      :mastertable="mastertable"
                      class="componentsClass"
                      :on-click-open-list="onClickOpenList"
                      :sg-bar-code="sgBarCode"
                      :link-report-v2 = "linkReportV2"
                      :on-click-upload-image-list="onClickUploadImageList"
                      :on-click-upload-affix-list="onClickUploadAffixList"
                      :delete-focuse-row="deleteFocuseRow"
                      :add-focuse-row="addFocuseRow"
                      :tablerowindex="ri"
                      :refresh="refresh"
                      :show-preloader="showPreloader"
                      :hide-preloader="hidePreloader"
              >
              </component>
            </div>
          </van-collapse-item>
          <div style="position: relative;" v-if="!config.showCollapse">
                <component
                        :page-setup="pageSetup"
                        :is="item.component"
                        v-for="(item, index) in config.fieldJson"
                        :table="item.table"
                        :data-set="dataSet"
                        :key="index"
                        :value="item.value"
                        :label="item.label"
                        :config="item.setStyle"
                        :id="item.id"
                        :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' : item.setStyle.col === '1/4' ? '25%' : '100%'
                }"
                        :is-edit-saved="isEditSaved"
                        :resource-home = 'resourceHome'
                        :on-click-upload-image="onClickUploadImage"
                        :pop-image-info="popImageInfo"
                        :on-click-show-image="onClickShowImage"
                        :remove-image="removeImage"
                        :remove-affix="removeAffix"
                        :rowindex = "ri"
                        :tablecode = "config.TableCode"
                        :change-value="changeValue"
                        :edit-field-before="editFieldBefore"
                        :edit-field-before-v2="editFieldBeforeV2"
                        :on-click-upload-affix="onClickUploadAffix"
                        :pop-affix-info="popAffixInfo"
                        :preview-affix="previewAffix"
                        :edit-field-after="editFieldAfter"
                        :field="item.setStyle.field"
                        :tables="tables"
                        :mastertable="mastertable"
                        class="componentsClass"
                        :on-click-open-list="onClickOpenList"
                        :sg-bar-code="sgBarCode"
                        :link-report-v2 = "linkReportV2"
                        :on-click-upload-image-list="onClickUploadImageList"
                        :on-click-upload-affix-list="onClickUploadAffixList"
                        :delete-focuse-row="deleteFocuseRow"
                        :add-focuse-row="addFocuseRow"
                        :tablerowindex="ri"
                        :refresh="refresh"
                        :show-preloader="showPreloader"
                        :hide-preloader="hidePreloader"
                >
                </component>
            </div>

          <template #right>
            <van-button  v-if="!isEditSaved"  square type="danger" text="删除" @click="deleteFocuseRow(ri, config.TableCode)"/>
          </template>
        </van-swipe-cell>
      </van-collapse>
        <div style="height: 10px;background-color: #F2F3F5;"></div>
      <div style=" background-color: #F2F3F5"  v-if="!isEditSaved"  @click="addFocuseRowV2(config.TableCode, true)">
        <van-row>
          <van-col span="24">
            <van-button round plain  type="info"  style="color: #1989fa; height: 30px; width: 100%">
              <span style="font-size:.9rem;font-weight:bold">+</span> <span
                    style="font-size:.7rem;">{{config.label}}</span>
            </van-button>
          </van-col>
        </van-row>
      </div>
      <div  style="display: inline;color: #1989fa; position: absolute; top: 40px; right: 0;float: right;" @click="addFocuseRowV2(config.TableCode, true)"  v-if="!isEditSaved">
        <van-icon name="add" size="24" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Row, Col, Button, Collapse, CollapseItem, SwipeCell } from 'vant'
export default {
  name: 'nxMobileGroup',
  components: {
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [SwipeCell.name]: SwipeCell
  },
  props: {
      pageSetup: Object,
      mastertable: Object,
      tables: Array,
      value: String,
      config: Object,
      children: Array,
      field: Object,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      rowindex: Number,
      tablecode: String,
      resourceHome: String,
      dataSet: Object,
      changeValue: Function,
      addFocuseRow: Function,
      editFieldBefore: Function,
      editFieldBeforeV2: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function,
      editFieldAfter: Function,
      deleteFocuseRow: Function,
      linkReportV2: Function,
      onClickOpenList: Function,
      sgBarCode: Function,
      refresh: {
          type: Boolean,
          default: false
      },
      onClickUploadImageList: Function,
      onClickUploadAffixList: Function,
      showPreloader: Function,
      hidePreloader: Function
  },
  data () {
    return {
        weightList: {
            0: 'inherit',
            33: 'normal',
            66: 'bold',
            99: 'bolder'
        },
        sizeList: {
            0: '10',
            33: '14',
            66: '16',
            99: '20'
        },
        activeNames: [0],
        deleShow: true,
        rows: []
    }
  },
    watch: {
        dataSet: {
            async handler () {
                this.rows = []
                for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
                    this.rows.push(i)
                }
                if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                    for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
                        if (this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode] !== this.dataSet[this.config.TableCode][i][this.config.OutFieldCode]) {
                            if (i < this.dataSet[this.config.TableCode].length - 1) {
                                this.rows[i + 1] = this.rows[i]
                            }
                        }
                        else {
                            if (i < this.dataSet[this.config.TableCode].length - 1) {
                                this.rows[i + 1] = this.rows[i] + 1
                            }
                        }
                    }
                }
            },
            deep: true
        }
    },
  created () {
      this.rows = []
      for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
          this.rows.push(i)
      }
      if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
          for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
              if (this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode] !== this.dataSet[this.config.TableCode][i][this.config.OutFieldCode]) {
                  if (i < this.dataSet[this.config.TableCode].length - 1) {
                      this.rows[i + 1] = this.rows[i]
                  }
              }
              else {
                  if (i < this.dataSet[this.config.TableCode].length - 1) {
                      this.rows[i + 1] = this.rows[i] + 1
                  }
              }
          }
      }
  },
  methods: {
      rowSchem (dataSet, config, rowIndex) {
          let strSchem = ''
          for (let i = 0; i < config.fieldScheam.length; i++) {
              const field = config.fieldScheam[i]
              strSchem += field.FieldTitle + ':' + (dataSet[config.TableCode][rowIndex][field.FieldName] === undefined ? '' : dataSet[config.TableCode][rowIndex][field.FieldName]) + '  '
          }
          return strSchem
      },
      addFocuseRowV2 (tableCode, isGo) {
          if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
              this.addFocuseRow(tableCode, isGo, this.config.OutFieldCode, this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode])
          } else {
              this.addFocuseRow(tableCode, isGo)
          }
      }
  }
}
</script>

<style scoped>


  .tab_name {
    width: 100%;
    height: 30px;
    border-bottom: 2px solid #ffffff;
  }
  .tab_name .tab_pro {
    width: 150px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    border: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    float: left;
    text-align: center;
    background-color: #ffffff;
  }
</style>
