<template>
  <div style="position: relative;" class="report-v2" v-if="field.Visable">
    <van-field :required="field.Nullable == true"
                 :label-width="config.labelWidth"
               :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
            config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'">{{ config.name }}</div>
      </template>
      <template slot="input">
        <input :placeholder="config.description"
               @click="editFieldBefor" readonly  :disabled="isEditSaved || !field.Editable"
               v-model="dataSet[tablecode][rowindex][config.FieldCode]"
               :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px; '" />
      </template>
      <template #button>
        <van-button v-if="field.Linkers != null  && field.Linkers.length > 0"  type="default" style="width: 12px;height: 12px;" :icon="require('@/assets/images/report/linker.png')" @click="linkReport()"></van-button>
      </template>
    </van-field>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
              show-toolbar
              :columns="combItemList"
              @cancel="showPicker = false"
              @confirm="onCombSelected"
              :title="config.name"

      />
      <div style="height: 50px;" v-if="combAllowManual">
        <van-field v-model="combItemValue" placeholder="手工返回值" style="margin-left: 20%;width: 60%;text-align: center" >
          <template #button>
            <van-button size="small" type="info" @click="onCombValue" >确定</van-button>
          </template>
        </van-field>
      </div>
    </van-popup>
  </div>
</template>

<script>
    import { Field, Popup, Picker, Button } from 'vant'
    export default {
        name: 'nxMobileSelect',
        components: {
            [Field.name]: Field,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
            [Button.name]: Button

        },
        props: {       pageSetup: Object,
            mastertable: Object,
            tables: Array,
            dataSet: Object,
            config: Object,
            label: String,
            isEditSaved: Boolean,
            rowindex: Number,
            tablecode: String,
            editFieldBefore: Function,
            editFieldBeforeV2: Function,
            editFieldAfter: Function,
            linkReportV2: Function,
            changeValue: Function
        },
        data () {
            return {
                weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null,
                showPicker: false,
                combAllowManual: false,
                combItemValue: '',
                combItemList: []
            }
        },
        watch: {
        },
        mounted () {
            const tableList = this.$utils.deepClone(this.tables)
            tableList.push(this.mastertable)
            console.log(tableList)
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                if (table.TableCode === this.tablecode) {
                    for (let j = 0; j < table.Fields.length; j++) {
                        if (table.Fields[j].FieldCode === this.config.FieldCode) {
                            this.field = table.Fields[j]
                        }
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        },
        methods: {
            //      选择下拉规范后回填数据
            onCombSelected(checkResult) {
                this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, checkResult)
                this.showPicker = false
            },
            onCombValue () {
                this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, this.combItemValue)
                this.showPicker = false
                this.combItemValue = ''
            },
            async getPicker () {
                this.combAllowManual = this.field.AllowManual
                const resultCombItems = await this.$api.getCombItems({
                    DataSpecID: this.field.Editor.DataSpecID
                })
                console.log(resultCombItems)
                if (resultCombItems.Code == 0) {
                    if (resultCombItems.Items) {
                        this.combItemList = [''].concat(resultCombItems.Items)
                        this.showPicker = true
                    }
                }
                // const resultDataSpecID = await this.$api.getDataSpecID({
                //     DataSpecID: this.field.Editor.DataSpecID
                // })
                // if (resultDataSpecID.Code === 0) {
                //     const resultCombItems = await this.$api.getCombItems({
                //         DataSpecID: resultDataSpecID.Data
                //     })
                //     console.log(resultCombItems)
                //     if (resultCombItems.Code == 0) {
                //         if (resultCombItems.Items) {
                //             this.combItemList = [''].concat(resultCombItems.Items)
                //             this.showPicker = true
                //         }
                //     }
                // } else {
                //     alert(resultDataSpecID.Message)
                // }
            },
            async editFieldBefor () {
                this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
                const result = await this.editFieldBeforeV2()
                if (result) {
                    this.getPicker()
                }
            },
        }
    }
</script>

<style scoped>

</style>
